<template>

    <v-row no-gutters>
        <v-col>
          <div id="expense-list">
            <!-- app drawer -->
    
           
            <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
              <template v-slot:action="{ close }">
                <v-btn icon small @click="close()">
                  <v-icon small>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbars>
    
            <!-- list filters -->
    
            <v-card
              tile
              :flat="$vuetify.breakpoint.mdAndDown"
              :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'" >
              <v-toolbar
                v-if="!$vuetify.breakpoint.smAndDown"
                :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
                flat
                :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
              >
                <v-toolbar-title class="text-h6 font-weight-medium">
                  <v-icon large left>
                    {{ icons.mdiViewList }}
                  </v-icon>
                  Masraflar
                </v-toolbar-title>    
                <v-spacer></v-spacer>    
             <!--    <v-btn plain text small @click.stop="isFilterExpenseSidebarActive = !isFilterExpenseidebarActive">
                  <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                    <v-icon left>
                      {{ icons.mdiFilter }}
                    </v-icon>
                  </v-badge>
                  <v-icon v-else left>
                    {{ icons.mdiFilter }}
                  </v-icon>
                  Filitre
                </v-btn>    -->
                


                <v-btn
                  class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
                  depressed
                  x-large
                  @click="addExpense()"
                  @mouseenter="activeHover = true" @mouseleave="activeHover = false"
                >
                  <v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>
                  <span>Masraf Ekle</span>
                  <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
                </v-btn>


              
              </v-toolbar>
    
              <!-- table -->
              <v-data-table
                v-model="selectedRows"
                :headers="tableColumns"
                :items="expenseListTable"
                :options.sync="options"
                :items-per-page="15"
                fixed-header
                :height="dataTableHeight"
                :server-items-length="totalExpenseListTable"
                :loading="loading"
              >
                <!-- Text -->
    
                <template #[`item.dosya_adi`]="{ item }">
                  <v-card
                    tile
                    class="rounded d-flex align-center justify-center"
                    width="40"
                    height="40"
                    :color="item.pictures ? 'white' : 'primary'"
                  >
                  <v-tooltip right color="white">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex flex-column responsive-container">
                        <div
                          v-bind="attrs"
                          :class="
                            $vuetify.breakpoint.smAndDown
                              ? 'font-weight-bold '
                              : 'd-block font-weight-bold text-truncate mb-1'
                          "
                          v-on="on"
                        >
                          <v-img
                            v-if="item.pictures.kresim"
                            height="30"
                            width="30"
                            :src="'https://portalapi.tukas.com.tr/' + item.pictures.kresim"
                          ></v-img>
                          <v-icon v-else>
                            {{ icons.mdiCameraOff }}
                          </v-icon>
                  </div>
                </div>
              </template>
              <v-img
                v-if="item.pictures.bresim && !$vuetify.breakpoint.xsOnly"
                width="400"
                height="500"
                :src="'https://portalapi.tukas.com.tr/' + item.pictures.bresim"
              ></v-img>
            </v-tooltip>                    
              </v-card>
                </template>
    
                <template #[`item.expense_statu`]="{ item }">
                  <v-chip  :color="item.expense_statu === '1' ? 'success' : 'warning'" link outlined small>
                    <span v-if="item.expense_statu === '1'">Onaylandı</span>
                    <span v-else-if="item.expense_statu === '2'" >Reddedildi</span>
                    <span v-else >Onay Bekliyor</span>
                    <!-- {{ item.expense_status === '1' ? 'Onaylandı' : 'Onay Bekliyor' }} -->                    
                  </v-chip>
                </template>
                <template #[`item.expense_date`]="{ item }">

                  <div class="text-right" >
                    {{ item.expense_date | moment('Do MMMM YYYY') }}
                  </div>
                </template>
    

                <template #[`item.expense_amount`]="{ item }">              
                  <div class="text-right" >
                    <CurrencyInput v-model="item.expense_amount" currency="TRY"/>                  
                  </div>        
                </template>

               
                <template #[`item.username`]="{ item }">
                 
                    {{ item.username }}

                 <!--  <div class="text-caption">
                    {{ item.username }}
                  </div> -->
                </template>
                <template #[`item.expense_desc`]="{ item }">
                  <div class="text-caption">
                    {{ item.expense_desc }}
                  </div>
              
                </template>
    
                <template #[`item.actions`]="{ item }">
    
                <!--   <v-tooltip v-if="item.dosya_adi" bottom color="warning">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        color="warning"
                        x-small
                        plain
                        class="rounded-l-0 rounded-r-xl"
                        v-bind="attrs"
                        @click="expenseImageDelete(item)"
                        v-on="on"
                      >
                        <v-icon small>
                          {{ icons.mdiCameraOff }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Resimi Kaldır</span>
                  </v-tooltip>
                  <v-tooltip v-else bottom color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        color="nightDark"
                        x-small
                        class="rounded-l-0 rounded-r-xl"
                        v-bind="attrs"
                        @click="expenseImageUpload(item)"
                        v-on="on"
                      >
                        <v-icon small>
                          {{ icons.mdiCamera }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Resim Yükle</span>
                  </v-tooltip> -->
    
                  <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="item.expense_statu === '0'" depressed color="error" x-small plain v-bind="attrs" @click="expenseDelete(item)" v-on="on">
                        <v-icon small>
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Masrafı Sil</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>

</template>
<script>
import router from '@/router'
import ImageUpload from '@/components/ImageUpload.vue'
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiCollage,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSquareEditOutline,
  mdiViewList
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
//import expenseStoreModule from '../expenseStoreModule'
import expenseAdd from './ExpenseAdd.vue'
/* import expenseFilter from './ExpenseFilter.vue' */

import VCurrencyField from '@/views/expense/currency/VCurrencyField'
import CurrencyInput from '@/views/expense/currency/CurrencyInput'

export default {
  components: {
    expenseAdd,
    //productFilter,
    ImageUpload,
    VSnackbars,
    CurrencyInput,
    VCurrencyField
  },
  setup() {
    const EXPENSE_APP_STORE_MODULE_NAME = 'app-expense'
    const snackbars = ref([])

    // this won't work because we are passing a number to watch()

    // Register module
/*     if (!store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) {
      store.registerModule(EXPENSE_APP_STORE_MODULE_NAME, expenseStoreModule)
    } */

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXPENSE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EXPENSE_APP_STORE_MODULE_NAME)
      }
    })

    const isAddUpdateExpenseSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterExpenseSidebarActive = ref(false)
    const isUploadExpenseImageActive = ref(false)
    const dataUpload = ref({})
    const expenseListTable = ref([])
    const expenseType = ref('add')
    const expenseItem = ref({})

    const tableColumns = [    
      {
        text: 'ID',
        value: 'id',
        width: '90px',
      },
      {
        text: 'Resim',
        value: 'dosya_adi',
        width: '90px',
      },    
      {
        text: 'Durum',
        value: 'expense_statu',
        width: '90px',
      },
      {
        text: 'Masraf Tarihi',
        value: 'expense_date',
        width: '150px',
      }, 
      {
        text: 'Kullanıcı',
        value: 'username',
        width: '100px',
      },
      {
        text: 'Masraf Tutarı',
        value: 'expense_amount',
        width: '180px',
      }, 
      {
        text: 'Açıklama',
        value: 'expense_desc',
        width: '300px',
      },     
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalExpenseListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const expenseTotalLocal = ref([])
    const selectedRows = ref([])

    const expenseImageUpload = item => {
      dataUpload.value = item
      isUploadExpenseImageActive.value = true
    }
    const uploadImage = item => {
      dataUpload.value = item
      isUploadExpenseImageActive.value = true
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const expenseImageDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.pictures.bresim[0].uploadid,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Masraf Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchExpenses()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const expenseDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Masrafı silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'expenseDelete',
              id: item.id,
            })
            .then(() => {
              snackbars.value.push({
                message: `Masraf Silindi`,
                color: 'error',
                timeout: 5000,
              })

              fetchExpenses()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const addExpense = () => {
      router.push({ name: 'expense-add' })
    }


    const filterData = item => {
      idFilter.value = item.id || null
      statusFilter.value = item.expense_statu || null
      urunKodFilter.value = item.urun_kodu || null
      baslikFilter.value = item.baslik || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }
    const fetchExpenses = () => {
      const query = {
        options: options.value        
      }      
      const method = 'expenseList'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          expenseListTable.value = response.data
          totalExpenseListTable.value = Number(response.pagination.totalRec)
          loading.value = false
          console.log(totalExpenseListTable.value )
          
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchExpenses()
    })

    return {
      expenseImageDelete,
      statusFind,
      expenseItem,
      addExpense,
      expenseType,
      isFilterNumber,
      filterData,
      expenseListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalExpenseListTable,
      loading,
      options,
      expenseTotalLocal,
      isAddUpdateExpenseSidebarActive,
      isFilterExpenseSidebarActive,
      isUploadExpenseImageActive,
      selectedRows,
      fetchExpenses,
      expenseDelete,
      snackbars,
      expenseImageUpload,
      dataUpload,
      uploadImage,
      saveImageEvent,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiCollage,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiViewList
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      activeHover : false,
      modul:"expense",
      image_isActive:0
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>