var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"expense-list"}},[_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiViewList)+" ")]),_vm._v(" Masraflar ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text",attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.addExpense()},"mouseenter":function($event){_vm.activeHover = true},"mouseleave":function($event){_vm.activeHover = false}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Masraf Ekle")]),(_vm.activeHover)?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/misc/tukas-yaprak.png"),"width":"20"}})]):_vm._e()],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.expenseListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"server-items-length":_vm.totalExpenseListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dosya_adi",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"rounded d-flex align-center justify-center",attrs:{"tile":"","width":"40","height":"40","color":item.pictures ? 'white' : 'primary'}},[_c('v-tooltip',{attrs:{"right":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex flex-column responsive-container"},[_c('div',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.smAndDown
                          ? 'font-weight-bold '
                          : 'd-block font-weight-bold text-truncate mb-1'},'div',attrs,false),on),[(item.pictures.kresim)?_c('v-img',{attrs:{"height":"30","width":"30","src":'https://portalapi.tukas.com.tr/' + item.pictures.kresim}}):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])],1)])]}}],null,true)},[(item.pictures.bresim && !_vm.$vuetify.breakpoint.xsOnly)?_c('v-img',{attrs:{"width":"400","height":"500","src":'https://portalapi.tukas.com.tr/' + item.pictures.bresim}}):_vm._e()],1)],1)]}},{key:"item.expense_statu",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.expense_statu === '1' ? 'success' : 'warning',"link":"","outlined":"","small":""}},[(item.expense_statu === '1')?_c('span',[_vm._v("Onaylandı")]):(item.expense_statu === '2')?_c('span',[_vm._v("Reddedildi")]):_c('span',[_vm._v("Onay Bekliyor")])])]}},{key:"item.expense_date",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.expense_date,'Do MMMM YYYY'))+" ")])]}},{key:"item.expense_amount",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('CurrencyInput',{attrs:{"currency":"TRY"},model:{value:(item.expense_amount),callback:function ($$v) {_vm.$set(item, "expense_amount", $$v)},expression:"item.expense_amount"}})],1)]}},{key:"item.username",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.username)+" ")]}},{key:"item.expense_desc",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.expense_desc)+" ")])]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [(item.expense_statu === '0')?_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"error","x-small":"","plain":""},on:{"click":function($event){return _vm.expenseDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Masrafı Sil")])])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }